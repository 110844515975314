import { graphql } from "gatsby";
import React, {useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import PageLayout from "../components/PageLayout";
import { Container } from "react-bootstrap";
import { api } from "../utilities/api";

const RefundForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 20vh; /* Adjust the height as needed */
  color: rgb(4, 148, 202);
`;

const CheckmarkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(4, 148, 202);
  margin-bottom: 8px;
  margin-top: 24px;
`;

const stroke = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const Checkmark = styled.svg`
  width: 30px;
  .checkmark-circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #fff;
    fill: none;
    animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  .checkmark-check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #fff;
    fill: none;
    animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.6s forwards;
  }
`;

const Refund = () => {

  const [isSuccess, setIsSuccess] = useState(false);
  const [errorContent, setErrorContent] = useState("");

  useEffect(() => {
    // Parse the search query parameters
    const searchParams = new URLSearchParams(window.location.search);
    let uid = searchParams.get('uid');

    if (uid) {
      // check that all spaces have been converted to pluses, due to URI encoding
      uid = uid.replace(/ /g, "+");
      console.log("uid found: ", uid);
      api.submitRefundRequest(uid)
        .then(res => {
          if (res.data.includes("[bad request]")) {
            console.error(res.data);
            setIsSuccess(false);
            if (res.data.includes("already requested")) {
              setErrorContent("This refund has already been requested. Please contact our support team if you require further assistance.");
            }
            if (res.data.includes("customer id is not correct")) {
              setErrorContent("Sorry, your customer id doesn't appear to be correct. Please contact our support team if you require further assistance.");
            }
            if (res.data.includes("your request properly")) {
              setErrorContent("Sorry, something went wrong. Please contact our support team if you require further assistance.");
            }
          } else if (res.data.includes("success")) {
            setIsSuccess(true);
          }
        })
        .catch(err => {
          console.error(err);
          setIsSuccess(false);
          setErrorContent("Sorry, something went wrong. Please contact our support team if you require further assistance.");
        })
    }


  }, []); 

  return (
    <PageLayout>
      <title>Survey | PhoneBox</title>
      <Container>
        {isSuccess &&
          <RefundForm>
            <CheckmarkContainer>
              <Checkmark viewBox="0 0 52 52">
                <circle className="checkmark-circle" cx="26" cy="26" r="25"/>
                <path className="checkmark-check" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </Checkmark>
            </CheckmarkContainer>
            <h4 style={{ marginTop: 20 }}>Your refund has been accepted.</h4>
          </RefundForm>
        }
        {!isSuccess &&
          <RefundForm>
            <h4 style={{ marginTop: 60, textAlign: "center" }}>{errorContent}</h4>
          </RefundForm>
        }
      </Container>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Refund;
